<template>
  <div class="video">
    <el-container>
      <el-header>
        <el-row class="top">
          <el-col :span="20">
            <el-input type="input" v-model="search"></el-input>
          </el-col>
          <el-col :span="4">
            <el-button type="primary">搜索</el-button>
          </el-col>
        </el-row>
      </el-header>
      <!--      主体部分-->
      <el-main>
        <el-row :gutter="20">
          <el-col :span="20">
            <el-row :gutter="15">
              <el-col :span="6" v-for="(o, index) in course.records" :key="index" class="item">
                <el-card :body-style="{ padding: '0px' }" class="card" @click.native="change(o.id)">
                  <el-image
                      class="image"
                      :src="o.courseUrl"
                      fit="fill">
                  </el-image>
                  <div style="padding: 10px;">
                    <div class="name">课程名字: {{ o.courseName }}</div>
                    <div class="name play">
                      <div class="views">
                        <i class="el-icon-video-play"></i>
                        <span>{{ o.courseViews }}</span>
                      </div>
                      <div>
                        <i class="fa fa-thumbs-up"></i>
                        <span>{{ o.courseGood }}</span>
                      </div>
                    </div>
                    <div class="bottom">
                      <el-avatar class="avatar" :src="o.userImg"></el-avatar>
                      <div class="username">{{ o.username }}</div>
                      <div>{{ o.gmtCreate }}</div>
                    </div>
                  </div>
                </el-card>
              </el-col>
            </el-row>
            <el-row>
              <el-pagination
                  background
                  :current-page="course.current"
                  :page-size="course.size"
                  @current-change="get"
                  layout="total, prev, pager, next, jumper"
                  :total="course.total"
              >
              </el-pagination>
            </el-row>
          </el-col>
          <el-col :span="4">
            你好
          </el-col>
        </el-row>
      </el-main>
    </el-container>
  </div>
</template>

<script>
import {getAllCourse} from "@/api/course"

export default {
  name: "home-page",
  data() {
    return {
      search: "",
      course: []
    }
  },
  mounted() {
    this.get(1);
  },
  methods: {
    get(number) {
      getAllCourse(number).then(result => {
        // 设置 课程信息
        this.course = result.data.course;
        console.log(this.course)
      }).catch(error => {
        console.log(error.message)
      })
    },
    change(id) {
      console.log("点击了" + id)
      // 跳转页面
      this.$router.push({path: `/video/show/${id}/`})
      // this.$router.push({path: "/video/show", query: {id}})
    }
  },
};
</script>

<style scoped>


/*主体部分*/

/* 图片*/
.card {
  padding: 0;
  margin-bottom: 10px;

}

.image {
  height: 8rem;
  width: 100%;
}

.video {
  width: 90%;
  height: 100%;
  background-color: #fff;
  padding: 10px;
  margin: 5px auto;
}

/*video底部的信息*/
.bottom {
  width: 100%;
  height: 1.5rem;
}

.bottom div {
  height: 24px;
  line-height: 24px;
}

.username {
  float: left;
}

.avatar {
  float: left;
  width: 24px;
  height: 24px;
  margin-right: 10px;
}

.name {
  line-height: 1.25rem;
  height: 1.25rem;
  text-align: left;
  font-size: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #20232c;
  margin-bottom: 6px;
}

.play {
  display: flex;
}

.views {
  margin-right: 20px;
}
 i{
  margin-right: 5px;
}
</style>
